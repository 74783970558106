var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"xl":"8","lg":"8","md":"12","sm":"12"}},[_c('b-card',{attrs:{"title":"Add New Product"}},[_c('b-card-sub-title',{staticClass:"mb-2"},[_vm._v(" Complete the form below to add this product to the AMP ")]),_c('validation-observer',{ref:"addProductValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v("Product Name ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"category"}},[_vm._v("Product Category ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"category","options":_vm.categories,"label":"title"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',{attrs:{"description":"Allowed JPG, JPEG, PNG or GIF"}},[_c('label',{attrs:{"for":"logo"}},[_vm._v("Product Logo ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"product logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"logo","accept":"image/jpeg, image/png, image/gif, image/jpg"},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"link"}},[_vm._v("Registration Link ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"registration link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","state":errors.length > 0 ? false:null,"name":"registrationLink","placeholder":"https://product-registration-link.com"},model:{value:(_vm.registrationLink),callback:function ($$v) {_vm.registrationLink=$$v},expression:"registrationLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"url"}},[_vm._v("Product URL ")]),_c('validation-provider',{attrs:{"name":"product URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false:null,"name":"productUrl","placeholder":"https://product-url.com"},model:{value:(_vm.productUrl),callback:function ($$v) {_vm.productUrl=$$v},expression:"productUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"description"}},[_vm._v("Description")]),_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Brief description of product","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12","mt-2":""}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.addProduct}},[_vm._v(" Add Product ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'products' },"variant":"outline-warning"}},[_vm._v(" Go Back ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }